import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import styled from '@emotion/styled';

const StyledAppBar = styled(AppBar)`
  background-color: var(--color-primary);
`;

const Logo = styled(Typography)`
  flex-grow: 1;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
`;

const NavButton = styled(Button)`
  color: var(--color-white);
  &:hover {
    color: var(--color-accent);
  }
`;

const Navbar = ({ user, onLogout }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    onLogout();
    navigate('/login');
    handleClose();
  };

  return (
    <StyledAppBar position="static">
      <Toolbar>
        <Logo variant="h6">PIM BY ELI</Logo>
        {user ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
              sx={{ display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/" onClick={handleClose}>Home</MenuItem>
              <MenuItem component={Link} to="/locations" onClick={handleClose}>Locations</MenuItem>
              {user.role === 'admin' && (
                <>
                  <MenuItem component={Link} to="/settings" onClick={handleClose}>Settings</MenuItem>
                  <MenuItem component={Link} to="/pos-management" onClick={handleClose}>POS Management</MenuItem>
                  <MenuItem component={Link} to="/user-management" onClick={handleClose}>User Management</MenuItem>
                </>
              )}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <div className="navbar-links" sx={{ display: { xs: 'none', sm: 'block' } }}>
              <NavButton component={Link} to="/">Home</NavButton>
              <NavButton component={Link} to="/locations">Locations</NavButton>
              {user.role === 'admin' && (
                <>
                  <NavButton component={Link} to="/settings">Settings</NavButton>
                  <NavButton component={Link} to="/pos-management">POS Management</NavButton>
                  <NavButton component={Link} to="/user-management">User Management</NavButton>
                </>
              )}
              <NavButton onClick={handleLogout}>Logout</NavButton>
            </div>
          </>
        ) : (
          <NavButton component={Link} to="/login">Login</NavButton>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;