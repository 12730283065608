import React, { useState, useEffect } from 'react';
import { getMode, updateMode, addOrUpdateSetting, getAllSettings } from '../services/api';
import { Container, TextField, Button, Typography, Paper, Box, Select, MenuItem, FormControl, InputLabel, Switch, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: 'var(--color-primary)',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: 'var(--color-accent)',
  color: 'var(--color-primary)',
  '&:hover': {
    backgroundColor: 'var(--color-secondary)',
  },
}));

function Settings() {
  const [mode, setMode] = useState('');
  const [newSetting, setNewSetting] = useState({ key: '', value: '', type: 'string' });
  const [allSettings, setAllSettings] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchMode();
    fetchAllSettings();
  }, []);

  const fetchMode = async () => {
    try {
      const response = await getMode();
      setMode(response.data.mode);
    } catch (error) {
      console.error('Error fetching mode:', error);
    }
  };

  const fetchAllSettings = async () => {
    try {
      const response = await getAllSettings();
      setAllSettings(response.data);
    } catch (error) {
      console.error('Error fetching all settings:', error);
    }
  };

  const handleModeChange = async (event) => {
    const newMode = event.target.value;
    try {
      await updateMode(newMode);
      setMode(newMode);
      setMessage('Mode updated successfully');
    } catch (error) {
      console.error('Error updating mode:', error);
      setMessage('Failed to update mode');
    }
  };

  const handleNewSettingChange = (event) => {
    const { name, value } = event.target;
    setNewSetting(prev => ({ ...prev, [name]: value }));
  };

  const handleAddSetting = async (event) => {
    event.preventDefault();
    try {
      await addOrUpdateSetting(newSetting);
      setMessage('Setting added successfully');
      setNewSetting({ key: '', value: '', type: 'string' });
      fetchAllSettings();
    } catch (error) {
      console.error('Error adding setting:', error);
      setMessage('Failed to add setting');
    }
  };

  return (
    <StyledContainer>
      <Title variant="h4" gutterBottom>
        Settings
      </Title>
      
      <StyledPaper>
        <Title variant="h6" gutterBottom>
          Application Mode
        </Title>
        <FormControl fullWidth>
          <InputLabel id="mode-select-label">Mode</InputLabel>
          <Select
            labelId="mode-select-label"
            value={mode}
            onChange={handleModeChange}
            label="Mode"
          >
            <MenuItem value="production">Production</MenuItem>
            <MenuItem value="development">Development</MenuItem>
          </Select>
        </FormControl>
      </StyledPaper>

      <StyledPaper>
        <Title variant="h6" gutterBottom>
          Add New Setting
        </Title>
        <form onSubmit={handleAddSetting}>
          <TextField
            fullWidth
            label="Key"
            name="key"
            value={newSetting.key}
            onChange={handleNewSettingChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Value"
            name="value"
            value={newSetting.value}
            onChange={handleNewSettingChange}
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              labelId="type-select-label"
              name="type"
              value={newSetting.type}
              onChange={handleNewSettingChange}
              label="Type"
            >
              <MenuItem value="string">String</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
            </Select>
          </FormControl>
          <StyledButton type="submit" variant="contained" fullWidth>
            Add Setting
          </StyledButton>
        </form>
      </StyledPaper>

      <StyledPaper>
        <Title variant="h6" gutterBottom>
          All Settings
        </Title>
        {allSettings.map((setting) => (
          <Box key={setting.key} mb={2}>
            <Typography variant="subtitle1">{setting.key}</Typography>
            {setting.type === 'boolean' ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={setting.value === 'true'}
                    onChange={() => {/* Implement update logic */}}
                    name={setting.key}
                    color="primary"
                  />
                }
                label={setting.value}
              />
            ) : (
              <Typography variant="body2">{setting.value}</Typography>
            )}
          </Box>
        ))}
      </StyledPaper>

      {message && (
        <Typography color="primary" align="center" mt={2}>
          {message}
        </Typography>
      )}
    </StyledContainer>
  );
}

export default Settings;