import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getLocationById, updateReplenishLevelsById } from '../services/api';
import { 
  Container, Typography, Grid, Card, CardContent, TextField, 
  IconButton, Box, Divider, CircularProgress, Switch, FormControlLabel
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LinkIcon from '@mui/icons-material/Link';
import { styled } from '@mui/material/styles';
import CustomButton from './CustomButton';
import logger from '../utils/logger';
import ReactECharts from 'echarts-for-react';

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  padding: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: 'var(--color-primary)',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  color: 'var(--color-primary)',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 600,
}));

const SheetLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: theme.spacing(1),
}));

const InventoryCategory = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

function LocationDetails() {
  const { id } = useParams();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingReplenishLevels, setEditingReplenishLevels] = useState({});
  const [message, setMessage] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [chartType, setChartType] = useState('bar');
  const [updating, setUpdating] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    fetchLocationDetails();
  }, [id]);

  const fetchLocationDetails = async () => {
    try {
      const data = await getLocationById(id);
      console.log('Fetched location details:', data);
      setLocation(data);
      setEditingReplenishLevels(data.ReplenishLevels || {});
      setLoading(false);
    } catch (err) {
      console.error('Error fetching location details:', err);
      setError('Failed to fetch location details. Please try again later.');
      setLoading(false);
    }
  };

  const handleReplenishLevelChange = (category, value) => {
    setEditingReplenishLevels(prev => ({
      ...prev,
      [category]: value === '' ? '' : parseInt(value, 10)
    }));
  };

  const saveReplenishLevels = async () => {
    try {
      setUpdateStatus('updating');
      setMessage('Updating replenish levels...');

      const updatedLevels = {
        G: editingReplenishLevels.G ?? location.ReplenishLevels?.G,
        M: editingReplenishLevels.M ?? location.ReplenishLevels?.M,
        G2: editingReplenishLevels.G2 ?? location.ReplenishLevels?.G2,
        M2: editingReplenishLevels.M2 ?? location.ReplenishLevels?.M2
      };

      const result = await updateReplenishLevelsById(id, updatedLevels);

      if (result.success) {
        setUpdateStatus('completed');
        setMessage(result.message);
        setLocation(prevLocation => ({
          ...prevLocation,
          ReplenishLevels: result.updatedLevels,
          InventorySheet: result.sheetLinks.inventorySheet,
          RecommendedInventorySheet: result.sheetLinks.replenishSheet,
          OrderSheet: result.sheetLinks.orderSheet
        }));
      } else {
        setUpdateStatus('error');
        setMessage(result.message);
      }
    } catch (err) {
      console.error('Error updating replenish levels:', err);
      setUpdateStatus('error');
      setMessage('Failed to update replenish levels. Please try again.');
    }
  };

  const resetReplenishLevels = () => {
    setEditingReplenishLevels(location.ReplenishLevels || {});
  };

  const getChartOption = () => {
    const categories = ['G', 'M', 'G2', 'M2'];
    const inventoryData = categories.map(category => location.InventoryByCategory?.[category] || 0);
    const replenishData = categories.map(category => location.ReplenishLevels?.[category] || 0);

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['Current Inventory', 'Replenish Level']
      },
      xAxis: {
        type: 'category',
        data: categories
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'Current Inventory',
          type: chartType,
          data: inventoryData,
          itemStyle: {
            color: '#3498db'
          }
        },
        {
          name: 'Replenish Level',
          type: chartType,
          data: replenishData,
          itemStyle: {
            color: '#e74c3c'
          }
        }
      ],
      toolbox: {
        feature: {
          saveAsImage: { show: true, title: 'Save as Image' }
        }
      }
    };
  };

  const handleReplenishLevels = async () => {
    setUpdating(true);
    setProgress(0);

    try {
      // Send the update to the backend
      updateReplenishLevelsById(id, editingReplenishLevels);

      // Start the progress simulation
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevProgress + 3.33; // Increase by 3.33% every second (30 seconds total)
        });
      }, 1000);

      // After 30 seconds, fetch the updated location details
      setTimeout(async () => {
        await fetchLocationDetails();
        setUpdating(false);
        clearInterval(interval);
      }, 30000);

    } catch (error) {
      console.error('Error updating replenish levels:', error);
      setUpdating(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!location) return <Typography color="error">Location not found</Typography>;

  return (
    <StyledContainer>
      <Title variant="h4" gutterBottom>{location.name}</Title>
      {message && <Typography color="secondary" gutterBottom>{message}</Typography>}
      {updateStatus === 'updating' && (
        <Typography color="info">Updating individual item replenish levels. This may take a few minutes...</Typography>
      )}
      {updateStatus === 'completed' && (
        <Typography color="success">All replenish levels have been updated successfully.</Typography>
      )}
      {updateStatus === 'timeout' && (
        <Typography color="warning">Update process is taking longer than expected. Please check back later.</Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">
                <InventoryIcon /> Inventory Levels
              </CardTitle>
              <Typography variant="h5" gutterBottom>Total: {location.TotalInventory || 'N/A'}</Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                {['G', 'M', 'G2', 'M2'].map(category => (
                  <Grid item xs={6} key={category}>
                    <InventoryCategory>
                      {category}: {location.InventoryByCategory?.[category] || 'N/A'}
                    </InventoryCategory>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">
                <AssessmentIcon /> Replenish Levels
              </CardTitle>
              {['G', 'M', 'G2', 'M2'].map(category => (
                <Box key={category} sx={{ mb: 2 }}>
                  <TextField
                    label={category}
                    type="number"
                    value={editingReplenishLevels[category] ?? ''}
                    onChange={(e) => handleReplenishLevelChange(category, e.target.value)}
                    size="small"
                    fullWidth
                    inputProps={{ min: 0 }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    Current: {location.ReplenishLevels?.[category] ?? 'N/A'}
                  </Typography>
                </Box>
              ))}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <IconButton onClick={resetReplenishLevels} color="secondary">
                  <UndoIcon />
                </IconButton>
                <IconButton onClick={handleReplenishLevels} color="primary" disabled={updating}>
                  <SaveIcon />
                </IconButton>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">
                <LinkIcon /> Sheet Links
              </CardTitle>
              <SheetLinks>
                {location.InventorySheet && (
                  <CustomButton 
                    href={location.InventorySheet} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Inventory Sheet
                  </CustomButton>
                )}
                {location.RecommendedInventorySheet && (
                  <CustomButton 
                    href={location.RecommendedInventorySheet} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Recommended Inventory Sheet
                  </CustomButton>
                )}
                {location.OrderSheet && (
                  <CustomButton 
                    href={location.OrderSheet} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Order Sheet
                  </CustomButton>
                )}
              </SheetLinks>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">
                <InventoryIcon /> Inventory Chart
              </CardTitle>
              <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={chartType === 'bar'}
                      onChange={() => setChartType(chartType === 'bar' ? 'line' : 'bar')}
                      name="chartTypeSwitch"
                    />
                  }
                  label={`Chart Type: ${chartType === 'bar' ? 'Bar' : 'Line'}`}
                />
                <Typography variant="body2" color="textSecondary">
                  Use the save icon in the top-right corner to save the chart as an image.
                </Typography>
              </Box>
              <ReactECharts option={getChartOption()} style={{ height: '400px' }} />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}

export default LocationDetails;