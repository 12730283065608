import React, { useState, useEffect, useRef } from 'react';
import { getLocations, updateReplenishLevelsById, runUpdateReplenishLevels, runReloadProcess } from '../services/api';
import { Container, Typography, Grid, Card, CardContent, IconButton, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import { styled } from '@mui/material/styles';
import CustomButton from './CustomButton';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  padding: theme.spacing(2), // Added padding to make the card longer
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: 'var(--color-primary)',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
  transform: props => props.expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SheetLinks = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1), // Added gap between links
}));

const ProgressBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 20,
  left: 20,
  right: 20,
  zIndex: 1000,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 8,
  padding: theme.spacing(2),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

function Locations() {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [editingLocation, setEditingLocation] = useState(null);
  const replenishSettingsRef = useRef(null);
  const [isReloading, setIsReloading] = useState(false);
  const [expandedLocation, setExpandedLocation] = useState(null);
  const [editingReplenishLevels, setEditingReplenishLevels] = useState({});
  const [originalReplenishLevels, setOriginalReplenishLevels] = useState({});
  const [reloadProgress, setReloadProgress] = useState(null);

  useEffect(() => {
    loadCachedLocations();
    fetchLocations();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const loadCachedLocations = () => {
    const cachedData = localStorage.getItem('cachedLocations');
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      console.log('Cached Locations:', parsedData);
      setLocations(parsedData);
      setLoading(false);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await getLocations();
      console.log('Fetched Locations:', response);
      if (Array.isArray(response)) {
        setLocations(response);
        setLoading(false);
        localStorage.setItem('cachedLocations', JSON.stringify(response));
      } else {
        throw new Error('Invalid data format received');
      }
    } catch (err) {
      console.error('Error fetching locations:', err);
      setError('Failed to fetch locations. Please try again later.');
      setLoading(false);
    }
  };

  const handleClickOutside = (event) => {
    if (replenishSettingsRef.current && !replenishSettingsRef.current.contains(event.target)) {
      setEditingLocation(null);
    }
  };

  const handleReplenishLevelChange = (locationId, category, value) => {
    setEditingReplenishLevels(prev => ({
      ...prev,
      [locationId]: {
        ...prev[locationId],
        [category]: parseInt(value) || 0
      }
    }));
  };

  const saveReplenishLevels = async (locationId) => {
    try {
      const updatedLevels = {
        G: editingReplenishLevels[locationId].G,
        M: editingReplenishLevels[locationId].M,
        G2: editingReplenishLevels[locationId].G2,
        M2: editingReplenishLevels[locationId].M2
      };

      const response = await updateReplenishLevelsById(locationId, updatedLevels);

      if (response && response.data) {
        setLocations(locations.map(loc => 
          loc.db_location_id === locationId 
            ? { ...loc, ReplenishLevels: response.data }
            : loc
        ));
        setMessage('Replenish levels updated successfully!');
        setOriginalReplenishLevels(prev => ({ ...prev, [locationId]: null }));
        setEditingReplenishLevels(prev => ({ ...prev, [locationId]: null }));
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (err) {
      console.error('Error updating replenish levels:', err);
      setMessage('Failed to update replenish levels. Please try again.');
    }
    setTimeout(() => setMessage(null), 3000);
  };

  const revertReplenishLevels = (locationId) => {
    setEditingReplenishLevels(prev => ({ ...prev, [locationId]: null }));
    setOriginalReplenishLevels(prev => ({ ...prev, [locationId]: null }));
  };

  const updateLevels = async (locationId) => {
    const location = locations.find(loc => loc.db_location_id === locationId);
    try {
      await updateReplenishLevelsById(locationId, {
        g_replenish_level: location.ReplenishLevels.G,
        m_replenish_level: location.ReplenishLevels.M,
        g2_replenish_level: location.ReplenishLevels.G2,
        m2_replenish_level: location.ReplenishLevels.M2
      });
      setMessage('Replenish levels updated successfully!');
      setEditingLocation(null);
    } catch (err) {
      console.error('Error updating replenish levels:', err);
      setMessage('Failed to update replenish levels. Please try again.');
    }
    setTimeout(() => setMessage(null), 20000);
  };

  const handleReload = async () => {
    setIsReloading(true);
    setMessage(null);
    setError(null);
    setReloadProgress({ step: 1, details: {} });

    try {
      const progressUpdates = await runReloadProcess();
      
      for (const update of progressUpdates) {
        setReloadProgress(update);
        // Reduced delay for smoother progress
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      const updatedLocations = await getLocations();
      if (Array.isArray(updatedLocations)) {
        setLocations(updatedLocations);
        localStorage.setItem('cachedLocations', JSON.stringify(updatedLocations));
        setMessage('Locations reloaded successfully!');
      } else {
        throw new Error('Invalid data format received during reload');
      }
    } catch (err) {
      console.error('Error reloading locations:', err);
      setError('Failed to reload locations. Please try again.');
    } finally {
      setIsReloading(false);
      setReloadProgress(null);
    }
  };

  const handleLocationClick = (locationId) => {
    navigate(`/locations/${locationId}`);
  };

  if (loading && locations.length === 0) return <div className="loading">Loading locations...</div>;
  if (error) return <div className="error">{error}</div>;

  console.log('Rendering Locations:', locations); // Add this line

  return (
    <StyledContainer>
      <Title variant="h4" gutterBottom>
        Locations
      </Title>
      {message && <Typography color="secondary" gutterBottom>{message}</Typography>}
      <Grid container spacing={3}>
        {locations.map((location) => (
          <Grid item xs={12} sm={6} md={4} key={location.db_location_id}>
            <StyledCard onClick={() => handleLocationClick(location.db_location_id)}>
              <CardContent>
                <Typography variant="h6" component="h2">
                  {location.name}
                </Typography>
                <Typography color="textSecondary">
                  Total Inventory: {location.TotalInventory}
                </Typography>
                <SheetLinks>
                  {location.InventorySheet && (
                    <CustomButton 
                      href={location.InventorySheet} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      Inventory Sheet
                    </CustomButton>
                  )}
                  {location.RecommendedInventorySheet && (
                    <CustomButton 
                      href={location.RecommendedInventorySheet} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      Recommended Inventory Sheet
                    </CustomButton>
                  )}
                  {location.OrderSheet && (
                    <CustomButton 
                      href={location.OrderSheet} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      Order Sheet
                    </CustomButton>
                  )}
                </SheetLinks>
                <ExpandButton
                  expanded={expandedLocation === location.db_location_id}
                  onClick={() => setExpandedLocation(expandedLocation === location.db_location_id ? null : location.db_location_id)}
                >
                  <ExpandMoreIcon />
                </ExpandButton>
                {expandedLocation === location.db_location_id && (
                  <>
                    <div className="inventory-breakdown">
                      <Typography>G: {location.InventoryByCategory?.G || 'N/A'}</Typography>
                      <Typography>M: {location.InventoryByCategory?.M || 'N/A'}</Typography>
                      <Typography>G2: {location.InventoryByCategory?.G2 || 'N/A'}</Typography>
                      <Typography>M2: {location.InventoryByCategory?.M2 || 'N/A'}</Typography>
                    </div>
                    <div className="replenish-levels">
                      <Typography variant="subtitle1">Replenish Levels:</Typography>
                      {['G', 'M', 'G2', 'M2'].map(category => (
                        <div key={category} className="replenish-level-input">
                          <TextField
                            label={category}
                            type="number"
                            value={
                              (editingReplenishLevels[location.db_location_id] && 
                               editingReplenishLevels[location.db_location_id][category]) ||
                              location.ReplenishLevels[category]
                            }
                            onChange={(e) => handleReplenishLevelChange(location.db_location_id, category, e.target.value)}
                            size="small"
                            style={{ width: '100px', marginRight: '10px' }}
                          />
                          <Typography 
                            variant="body2" 
                            color={
                              location.InventoryByCategory[category] < location.ReplenishLevels[category] ? 'error' :
                              location.InventoryByCategory[category] === location.ReplenishLevels[category] ? 'primary' :
                              'success'
                            }
                          >
                            Current: {location.InventoryByCategory[category]}
                          </Typography>
                        </div>
                      ))}
                      {editingReplenishLevels[location.db_location_id] && (
                        <div className="replenish-level-actions">
                          <IconButton onClick={() => saveReplenishLevels(location.db_location_id)} color="primary">
                            <SaveIcon />
                          </IconButton>
                          <IconButton onClick={() => revertReplenishLevels(location.db_location_id)} color="secondary">
                            <UndoIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <CustomButton 
        onClick={handleReload}
        disabled={isReloading}
        style={{ position: 'fixed', bottom: 20, right: 20 }}
      >
        {isReloading ? 'Reloading...' : 'Reload Data'}
      </CustomButton>
      <ReloadProgressSnackbar open={!!reloadProgress} progress={reloadProgress} />
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => setMessage(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setMessage(null)} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
}

function ReloadProgressSnackbar({ open, progress }) {
  if (!progress) return null;

  const steps = [
    "Validating POS locations",
    "Updating inventory levels",
    "Updating replenish levels",
    "Generating Google Sheets",
    "Finalizing data"
  ];

  const getProgressDetails = () => {
    switch (progress.step) {
      case 1:
        return `POS checked: ${progress.details.posChecked || 0}, New locations: ${progress.details.newLocations || 0}`;
      case 2:
      case 3:
        return `Items: ${progress.details.itemsProcessed || 0} / ${progress.details.totalItems || 0}`;
      case 4:
        return `Sheets: ${progress.details.sheetsGenerated || 0} / ${progress.details.totalSheets || 0}`;
      default:
        return '';
    }
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <ProgressBox>
        <Typography variant="subtitle1" gutterBottom>
          {steps[progress.step - 1]}
        </Typography>
        <LinearProgress 
          variant="determinate" 
          value={(progress.step / steps.length) * 100} 
          sx={{ marginBottom: 1 }}
        />
        <Typography variant="body2">
          {getProgressDetails()}
        </Typography>
      </ProgressBox>
    </Snackbar>
  );
}

export default Locations;