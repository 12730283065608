import api from '../services/api';

const logger = {
  info: (message, data) => {
    console.log(message, data);
    api.post('/logs', { level: 'info', message, data });
  },
  error: (message, data) => {
    console.error(message, data);
    api.post('/logs', { level: 'error', message, data });
  }
};

export default logger;