import axios from 'axios';

// Hard code the backend API URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://your-deployed-backend-url.com/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true
});

// Remove the API key from the default headers

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const login = async (credentials) => {
  const response = await api.post('/auth/login', credentials);
  if (response.data.token) {
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
  }
  return response;
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

export const getLocations = async () => {
  try {
    const response = await api.get('/locations');
    console.log('API Response:', response.data); // Add this line
    if (Array.isArray(response.data)) {
      return response.data;
    } else {
      console.error('Unexpected response format:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching locations:', error);
    return [];
  }
};

export const getMode = () => api.get('/settings/mode'); // Expected format: Object
export const updateMode = (mode) => api.put('/settings/mode', { mode }); // Expected format: Object
export const addOrUpdateSetting = (settingData) => api.post('/settings', settingData); // Expected format: Object
export const updateReplenishLevels = (db_location_id, recommended_inventory_levels) => 
  api.put('/locations/change-replenish-levels', { db_location_id, recommended_inventory_levels }); // Expected format: Object

export const getAllReplenishLevels = () => api.get('/replenish-levels'); // Expected format: Array
export const updateReplenishLevelsById = async (db_location_id, levels) => {
  try {
    // Send the update request to the backend
    api.put(`/replenish-levels/${db_location_id}`, {
      g_replenish_level: levels.G,
      m_replenish_level: levels.M,
      g2_replenish_level: levels.G2,
      m2_replenish_level: levels.M2
    });
    // Note: We're not awaiting the response here
  } catch (error) {
    console.error('Error in updateReplenishLevelsById:', error.response?.data || error.message);
    throw error;
  }
};

export const updateReplenishLevelsAndFetchLocations = async () => {
  await api.post('/replenish-levels/update'); // Expected format: Object
  const response = await api.get('/locations'); // Expected format: Array
  return response.data; // Ensure this returns the data array
};

export const fetchUsers = () => api.get('/users'); // Expected format: Array
export const addUser = (userData) => api.post('/users', userData); // Expected format: Object
export const updateUser = (userId, userData) => api.put(`/users/${userId}`, userData); // Expected format: Object
export const deleteUser = (userId) => api.delete(`/users/${userId}`); // Expected format: Object
export const fetchRoles = () => api.get('/users/roles'); // Expected format: Array
export const getPosSystems = () => api.get('/pos'); // Expected format: Array
export const getAllSettings = () => api.get('/settings'); // Expected format: Array

export const getSetting = async (key) => {
  const response = await api.get(`/settings/${key}`); // Ensure this is correct
  return response;
};

export const getPOSData = async () => {
  return axios.get(`${API_BASE_URL}/pos`);
};

export const syncPOSData = async () => {
  return axios.post(`${API_BASE_URL}/pos/sync`);
};

export const deletePos = (posId) => api.delete(`/pos/${posId}`);
export const addPos = (posData) => api.post('/pos', posData);

export const getLocationById = async (id) => {
  try {
    const response = await api.get(`/locations/${id}`);
    console.log('Location details response:', response.data); // Add this line for debugging
    return response.data;
  } catch (error) {
    console.error('Error fetching location details:', error);
    throw error;
  }
};

export const runUpdateReplenishLevels = async () => {
  try {
    const response = await fetch('/api/run-update-replenish-levels', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to run update replenish levels script');
    }
    return await response.json();
  } catch (error) {
    console.error('Error running update replenish levels script:', error);
    throw error;
  }
};

export const logMessage = (level, message, data) => 
  api.post('/logs', { level, message, data });

export const getTotalLocations = async () => {
  try {
    console.log('Fetching total locations and inventory');
    const response = await api.get('/locations/total');
    console.log('Received response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching total locations and inventory:', error);
    return { totalLocations: 0, totalInventory: 0 };
  }
};

export const getTotalOptimization = async () => {
  try {
    const response = await api.get('/optimization/total');
    return response.data;
  } catch (error) {
    console.error('Error fetching total optimization:', error);
    return { optimizationLevel: '0%' };
  }
};

export const getRecentUpdates = async () => {
  try {
    const response = await api.get('/updates/recent');
    console.log('API Response for recent updates:', response.data); // Add this line for debugging
    return response.data;
  } catch (error) {
    console.error('Error fetching recent updates:', error);
    throw error;
  }
};

export const getInventoryHistory = async () => {
  console.log('Fetching inventory history');
  try {
    const response = await api.get('/inventory/history');
    console.log('Inventory history response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory history:', error);
    throw error;
  }
};

export const runReloadProcess = async () => {
  try {
    const response = await api.post('/reload-process');
    return response.data;
  } catch (error) {
    console.error('Error running reload process:', error);
    throw error;
  }
};

export default api;