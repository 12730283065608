import React, { useState, useEffect } from 'react';
import { fetchUsers, fetchRoles, addUser, updateUser, deleteUser } from '../services/api';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit, Delete, Add } from '@mui/icons-material';
import CustomButton from './CustomButton'; // Import the new CustomButton

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: 'var(--color-primary)',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: 'var(--color-accent)',
  color: 'var(--color-primary)',
  '&:hover': {
    backgroundColor: 'var(--color-secondary)',
  },
}));

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState({ username: '', email: '', role_id: '', password: '' });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchUserData();
    fetchRoleData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetchUsers();
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchRoleData = async () => {
    try {
      const response = await fetchRoles();
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleOpenDialog = (user = { username: '', email: '', role_id: '', password: '' }) => {
    setCurrentUser(user);
    setIsEditing(!!user.id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentUser({ username: '', email: '', role_id: '', password: '' });
    setIsEditing(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        await updateUser(currentUser.id, currentUser);
      } else {
        await addUser(currentUser);
      }
      fetchUserData();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(userId);
        fetchUserData();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  return (
    <StyledContainer>
      <Title variant="h4" gutterBottom>
        User Management
      </Title>
      
      <CustomButton
        startIcon={<Add />}
        onClick={() => handleOpenDialog()}
      >
        Add New User
      </CustomButton>

      <StyledPaper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{roles.find(role => role.role_id === user.role_id)?.role_name}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenDialog(user)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteUser(user.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isEditing ? 'Edit User' : 'Add New User'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Username"
            type="text"
            fullWidth
            value={currentUser.username}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            value={currentUser.email}
            onChange={handleInputChange}
          />
          {!isEditing && (
            <TextField
              margin="dense"
              name="password"
              label="Password"
              type="password"
              fullWidth
              value={currentUser.password}
              onChange={handleInputChange}
            />
          )}
          <Select
            margin="dense"
            name="role_id"
            label="Role"
            fullWidth
            value={currentUser.role_id}
            onChange={handleInputChange}
          >
            {roles.map((role) => (
              <MenuItem key={role.role_id} value={role.role_id}>
                {role.role_name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseDialog}>Cancel</CustomButton>
          <CustomButton onClick={handleSubmit}>{isEditing ? 'Update' : 'Add'}</CustomButton>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
}

export default UserManagement;