import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Grid, Paper, Typography, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Camera, Package, Zap, BarChart2, Bell, MessageSquare } from 'lucide-react';
import { getTotalLocations, getTotalOptimization, getRecentUpdates, getInventoryHistory } from '../services/api';
import { formatDistanceToNow } from 'date-fns';
import ReactECharts from 'echarts-for-react';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 48,
  height: 48,
  borderRadius: '50%',
  backgroundColor: 'var(--color-accent)',
  marginBottom: theme.spacing(2),
}));

const ClickablePaper = styled(StyledPaper)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

function Home() {
  const navigate = useNavigate();
  const [totalLocations, setTotalLocations] = useState(null);
  const [totalInventory, setTotalInventory] = useState(null);
  const [optimizationLevel, setOptimizationLevel] = useState(null);
  const [recentUpdates, setRecentUpdates] = useState([]);
  const [inventoryHistory, setInventoryHistory] = useState([]);

  useEffect(() => {
    fetchTotalLocationsAndInventory();
    fetchOptimizationLevel();
    fetchRecentUpdates();
    fetchInventoryHistory(); // Make sure this function is called
  }, []);

  const fetchTotalLocationsAndInventory = async () => {
    try {
      console.log('Fetching total locations and inventory');
      const data = await getTotalLocations();
      console.log('Received data:', data);
      setTotalLocations(data.totalLocations);
      setTotalInventory(data.totalInventory);
    } catch (error) {
      console.error('Error fetching data:', error);
      setTotalLocations(0);
      setTotalInventory(0);
    }
  };

  const fetchOptimizationLevel = async () => {
    try {
      console.log('Fetching optimization level');
      const data = await getTotalOptimization();
      console.log('Received optimization data:', data);
      setOptimizationLevel(data.optimizationLevel);
    } catch (error) {
      console.error('Error fetching optimization level:', error);
      setOptimizationLevel('0%');
    }
  };

  const fetchRecentUpdates = async () => {
    try {
      const data = await getRecentUpdates();
      console.log('Received recent updates:', data);
      setRecentUpdates(data);
    } catch (error) {
      console.error('Error fetching recent updates:', error);
      setRecentUpdates([]);
    }
  };

  const fetchInventoryHistory = async () => {
    try {
      console.log('Fetching inventory history');
      const data = await getInventoryHistory();
      console.log('Received inventory history:', data);
      setInventoryHistory(data);
    } catch (error) {
      console.error('Error fetching inventory history:', error);
      setInventoryHistory([]);
    }
  };

  const handleFeatureRequest = () => {
    const subject = encodeURIComponent('Feature Request for Inventory Management System');
    const body = encodeURIComponent('Hello,\n\nI would like to request a new feature for the Inventory Management System:\n\n[Please describe your feature request here]\n\nThank you!');
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=pimbyeli@gmail.com&su=${subject}&body=${body}`, '_blank');
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  const stats = [
    { name: 'Total Locations', value: totalLocations !== null ? totalLocations : <CircularProgress size={24} />, icon: <Camera size={24} />, path: '/locations' },
    { name: 'Total Inventory', value: totalInventory !== null ? (typeof totalInventory === 'number' ? totalInventory.toLocaleString() : totalInventory) : <CircularProgress size={24} />, icon: <Package size={24} /> },
    { name: 'Optimization Level', value: optimizationLevel !== null ? optimizationLevel : <CircularProgress size={24} />, icon: <Zap size={24} /> },
  ];

  const updates = [
    { id: 1, message: 'New location added: Miami Warehouse', time: '2 hours ago' },
    { id: 2, message: 'Inventory levels updated for all locations', time: '1 day ago' },
    { id: 3, message: 'Optimization algorithm improved', time: '3 days ago' },
  ];

  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const inventoryChartOption = {
    title: {
      text: 'Total Inventory History'
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      data: inventoryHistory.map(item => item.date)
    },
    yAxis: {
      type: 'value',
      name: 'Total Inventory'
    },
    series: [{
      data: inventoryHistory.map(item => parseInt(item.total_inventory)),
      type: 'line',
      smooth: true
    }]
  };

  return (
    <StyledContainer>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Inventory Management Dashboard
      </Typography>
      
      <Grid container spacing={4}>
        {stats.map((item) => (
          <Grid item xs={12} sm={4} key={item.name}>
            <ClickablePaper onClick={() => item.path && handleCardClick(item.path)}>
              <IconWrapper>
                {item.icon}
              </IconWrapper>
              <Typography variant="h4" component="h2" gutterBottom>
                {item.value}
              </Typography>
              <Typography color="textSecondary">{item.name}</Typography>
            </ClickablePaper>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={4} style={{ marginTop: '2rem' }}>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" component="h3" gutterBottom>
              <BarChart2 size={24} style={{ marginRight: '0.5rem' }} />
              Inventory Overview
            </Typography>
            {inventoryHistory.length > 0 ? (
              <ReactECharts option={inventoryChartOption} style={{ height: 300 }} />
            ) : (
              <div style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            )}
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" component="h3" gutterBottom>
              <Bell size={24} style={{ marginRight: '0.5rem' }} />
              Recent Updates
            </Typography>
            {recentUpdates.length > 0 ? (
              recentUpdates.map((update) => (
                <div key={update.created_at} style={{ marginBottom: '1rem' }}>
                  <Typography variant="body1">{update.text}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {formatRelativeTime(update.created_at)}
                  </Typography>
                </div>
              ))
            ) : (
              <Typography color="textSecondary">No recent updates available</Typography>
            )}
          </StyledPaper>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<MessageSquare />}
          onClick={handleFeatureRequest}
        >
          Request a Feature
        </Button>
      </Grid>

      <Grid container justifyContent="center" style={{ marginTop: '1rem' }}>
        <Link to="/settings" style={{ textDecoration: 'none' }}>
          <Button color="primary">
            Add or Update Settings
          </Button>
        </Link>
      </Grid>

      {/* Feature request modal implementation goes here */}
    </StyledContainer>
  );
}

export default Home;