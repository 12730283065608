import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './components/Login';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Locations from './components/Locations';
import Settings from './components/Settings';
import POSManagement from './components/POSManagement';
import UserManagement from './components/UserManagement';
import { logout } from './services/api';
import LocationDetails from './components/LocationDetails';
import { jwtDecode } from 'jwt-decode';

// Hard code the frontend port if needed
const FRONTEND_PORT = 3000;

const theme = createTheme({
  palette: {
    mode: 'light', // Add this line
    primary: {
      main: '#1a2b3c',
    },
    secondary: {
      main: '#20b2aa',
    },
    background: {
      default: '#f5f7fa',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
    },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp * 1000 > Date.now()) {
            setUser(JSON.parse(localStorage.getItem('user')));
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          handleLogout();
        }
      }
      setLoading(false);
    };

    checkToken();
  }, []);

  const handleLogin = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    logout();
    setUser(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="App">
          <Navbar user={user} onLogout={handleLogout} />
          <Routes>
            <Route path="/login" element={user ? <Navigate to="/" /> : <Login onLogin={handleLogin} />} />
            <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
            <Route path="/locations" element={user ? <Locations /> : <Navigate to="/login" />} />
            <Route path="/locations/:id" element={<LocationDetails />} />
            <Route path="/settings" element={user && user.role === 'admin' ? <Settings /> : <Navigate to="/" />} />
            <Route path="/pos-management" element={user && user.role === 'admin' ? <POSManagement /> : <Navigate to="/" />} />
            <Route path="/user-management" element={user && user.role === 'admin' ? <UserManagement /> : <Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;