import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { getPosSystems, deletePos, addPos } from '../services/api';
import CustomButton from './CustomButton';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: 'var(--color-primary)',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
}));

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

function POSManagement() {
  const [posSystems, setPosSystems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedPos, setSelectedPos] = useState(null);
  const [newPos, setNewPos] = useState({ name: '', current_api_url: '', current_api_key: '' });

  useEffect(() => {
    fetchPosSystems();
  }, []);

  const fetchPosSystems = async () => {
    try {
      const response = await getPosSystems();
      setPosSystems(response.data);
    } catch (error) {
      console.error('Error fetching POS systems:', error);
    }
  };

  const handleDeleteClick = (pos) => {
    setSelectedPos(pos);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deletePos(selectedPos.pos_id);
      setPosSystems(posSystems.filter(pos => pos.pos_id !== selectedPos.pos_id));
      setOpenDialog(false);
    } catch (error) {
      console.error('Error deleting POS system:', error);
    }
  };

  const handleAddPos = async () => {
    try {
      await addPos(newPos);
      fetchPosSystems();
      setOpenAddDialog(false);
      setNewPos({ name: '', current_api_url: '', current_api_key: '' });
    } catch (error) {
      console.error('Error adding POS system:', error);
    }
  };

  return (
    <StyledContainer>
      <Title variant="h4" gutterBottom>
        POS Management
      </Title>
      <CustomButton
        startIcon={<Add />}
        onClick={() => setOpenAddDialog(true)}
        style={{ marginBottom: '20px' }}
      >
        Add POS
      </CustomButton>
      <Grid container spacing={3}>
        {posSystems.map((pos) => (
          <Grid item xs={12} sm={6} md={4} key={pos.pos_id}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="h2">
                  {pos.name}
                </Typography>
                <Typography color="textSecondary">
                  URL: <StyledLink href={pos.current_api_url} target="_blank" rel="noopener noreferrer">
                    {pos.current_api_url}
                  </StyledLink>
                </Typography>
              </CardContent>
              <IconButton
                onClick={() => handleDeleteClick(pos)}
                color="error"
                style={{ alignSelf: 'flex-end', margin: '8px' }}
              >
                <Delete />
              </IconButton>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the POS system "{selectedPos?.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setOpenDialog(false)}>Cancel</CustomButton>
          <CustomButton onClick={handleDeleteConfirm} color="error">Delete</CustomButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Add New POS System</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="POS Name"
            fullWidth
            value={newPos.name}
            onChange={(e) => setNewPos({ ...newPos, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="API URL"
            fullWidth
            value={newPos.current_api_url}
            onChange={(e) => setNewPos({ ...newPos, current_api_url: e.target.value })}
          />
          <TextField
            margin="dense"
            label="API Key"
            fullWidth
            value={newPos.current_api_key}
            onChange={(e) => setNewPos({ ...newPos, current_api_key: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setOpenAddDialog(false)}>Cancel</CustomButton>
          <CustomButton onClick={handleAddPos}>Add</CustomButton>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
}

export default POSManagement;
