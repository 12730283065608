import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { login } from '../services/api';
import { Container, TextField, Button, Typography, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(135deg, var(--color-primary), var(--color-secondary))',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: 400,
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: 'var(--color-primary)',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: 'red',
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: 'var(--color-accent)',
  color: 'var(--color-primary)',
  '&:hover': {
    backgroundColor: 'var(--color-secondary)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: 'var(--color-primary)',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'var(--color-secondary)',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'var(--color-accent)',
  },
}));

const API_URL = 'http://localhost:5000/api';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      console.log('Attempting login with:', { username, password });
      const response = await login({ username, password });
      console.log('Login response:', response);
      onLogin(response.data.user);
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid username or password');
    }
  };

  return (
    <Root>
      <StyledPaper>
        <Title variant="h4">
          Login
        </Title>
        {error && <ErrorText>{error}</ErrorText>}
        <form onSubmit={handleSubmit}>
          <StyledTextField
            fullWidth
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            margin="normal"
          />
          <StyledTextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            margin="normal"
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
          >
            Login
          </StyledButton>
        </form>
      </StyledPaper>
    </Root>
  );
}

export default Login;